import axios from 'axios'
import { SEARCH_API_CONFIG } from '@/config/search.api.config'
/* eslint-disable */
export default class SearchService {
  /**
   * [getSearchResults]
   * @param {[String]} term [search term]
   * @param {[String]} languageCode [valid sitecore language code]
   * @returns {[Object]}
   */
  static getSearchResults = async (term, languageCode, ind_key) => {
    let request = await axios.get(SEARCH_API_CONFIG.getSearchResults(term, languageCode, ind_key))
    return request
  }
}
