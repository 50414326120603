<template>
  <section class="list container">
    <div id="results">
      <div class="preheader d-flex justify-content-between" v-if="title">
        <h2>{{ title }}</h2>
        <ToggleArrow v-if="toggle === true" @click="handleToggleClick" section="toggleMe" :status="internalToggle">
        </ToggleArrow>
      </div>
      <header v-if="internalToggle && !!items && items.length === 0"
        class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
        <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
          <div style="margin-left: 50px">
            {{ i18n['data-table-search'].tcNoPresentationsMessage }}
          </div>
        </div>
        <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
          @click="triggerAction(addItems.action)">
          {{ addItems.text }}
        </button>
      </header>
      <div v-if="!!items && items.length > 0">
        <header v-if="internalToggle && !hideHeader" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <a class="btn btn-dark flex-0 mr-2" v-if="csvUrl.length > 0"
              @click.prevent="downloadReport(csvUrl, 'Search', 'csv')">{{ i18n['data-table-search'].tcExportCSV }}</a>
            <a class="btn btn-dark flex-0 mr-2" v-if="pdfUrl.length > 0"
              @click.prevent="downloadReport(pdfUrl, 'Search', 'pdf')">{{ i18n['data-table-search'].tcExportPDF }}</a>
            <b-form-input v-model="filter" @input="handleSearchTermChange"
              :placeholder="i18n['data-table-search'].tcSearch"
              class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
          </div>
          <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
            <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display && includeAddButton"
              @click="triggerAction(addItems.action)">
              {{ addItems.text }}
            </button>
          </div>
        </header>
        <main v-if="internalToggle" class="table-responsive">
          <b-table class="g-table" :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"
            :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sort-icon-left small>
            <div class="d-inline-flex align-items-start" slot="HEAD_Media" slot-scope="data" @click="log(data)">
              <b-button v-if="data.field.cTooltip" id="button-1" class="tooltip-button mr-1" style="top: -7px">?
              </b-button>
              <b-tooltip v-if="data.field.cTooltip" target="button-1" :title="data.field.cTooltip"></b-tooltip>
              {{ data.label }}
            </div>
            <template v-slot:cell(search_results)="data">
              <div v-if="data.item.type === 'View'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="" @click.prevent="viewKeyAndRedirect(data.item.ItemName)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-3" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col" style="vertical-align: middle">
                    <a href="#" style="underline" @click.prevent="viewKeyAndRedirect(data.item.ItemName)">
                      {{ data.item.Name }}
                    </a>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Individual'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data.item.id)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data.item.id)">{{
                    data.item.FirstName
                    }} {{ data.item.LastName }}</a>
                    <p>
                      #: {{ data.item.ind_account_id }}
                      <span v-if="!!data.item.JoinDate">, {{ i18n['data-table-search'].tcJoinDate }}: {{
                      data.item.JoinDate
                      }}</span><br />
                      <span v-if="!!data.item.Address1">{{ i18n['data-table-search'].tcAddress }}: {{ data.item.Address1
                      }} {{ data.item.City }}
                        {{ data.item.State }} {{ data.item.CountryISO }}</span><br />
                      <span v-if="!!data.item.MemberChurchName">{{ i18n['data-table-search'].tcChurch }}:
                        <a href="#" style="underline" @click.prevent="churchStoreKeyAndRedirect(data)">{{
                        data.item.MemberChurchName
                        }}</a>
                        {{ i18n['data-table-search'].tcDenomination }}: {{ data.item.Denomination }}</span>
                      <b-form-checkbox v-if="data.item.IsAMember === 'true'" disabled checked="checked" value="checked">
                        {{
                        i18n['data-table-search'].tcMember
                        }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsAFriend === 'true'" disabled checked="checked" value="checked">
                        {{
                        i18n['data-table-search'].tcFriend
                        }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsAProspect === 'true'" disabled checked="checked"
                        value="checked">{{
                        i18n['data-table-search'].tcProspect
                        }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsChurchPresenter === 'true'" disabled checked="checked"
                        value="checked">{{
                        i18n['data-table-search'].tcChurchPresenter
                        }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsWidow === 'true'" disabled checked="checked" value="checked">{{
                      i18n['data-table-search'].tcWidow
                      }}</b-form-checkbox>
                    </p>
                    <p v-if="data.item.SpouseKey !== null">
                      {{ i18n['data-table-search'].tcSpouse }}:
                      <a href="#" style="underline"
                        @click.prevent="individualStoreKeyAndRedirect(data.item.SpouseKey)">{{
                        data.item.SpouseFormalName
                        }}</a>, #: {{ data.item.SpouseaAccountID }}
                      <b-form-checkbox v-if="data.item.SpouseAMemeber === 'true'" disabled checked="checked"
                        value="checked">{{
                        i18n['data-table-search'].tcMember
                        }}</b-form-checkbox>
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Organization'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="underline" @click.prevent="orgStoreKeyAndRedirect(data)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a href="#" style="underline" @click.prevent="orgStoreKeyAndRedirect(data)">{{
                    data.item.OrganizationName
                    }}</a>
                    <p>
                      <b-form-checkbox v-if="data.item.IsPlacementFacility === 'true'" checked="checked" disabled
                        value="checked">{{ i18n['data-table-search'].tcPlacementFacility }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsChurch === 'true'" disabled checked="checked" value="checked">
                        {{
                        i18n['data-table-search'].tcChurch
                        }}</b-form-checkbox>
                      <b-form-checkbox v-if="data.item.IsGideonCardFacility === 'true'" checked="checked" disabled
                        value="checked">{{ i18n['data-table-search'].tcGideonCardFacility }}</b-form-checkbox>
                      Type: {{ data.item.OrganizationType }}<br />
                      <span v-if="data.item.OrgAddress1 != ''">{{ i18n['data-table-search'].tcAddress }}: {{
                      data.item.OrgAddress1
                      }}
                        {{ data.item.OrgAddress2 }} {{ data.item.OrgAddress3 }} {{ data.item.OrgState }}
                        {{ data.item.CountryAbr }}</span><br />
                      <span v-if="data.item.OrgPhoneNumberMasked != ''">{{ i18n['data-table-search'].tcPhone }}: {{
                      data.item.OrgPhoneNumberMasked
                      }}</span>
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Camp'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="underline" @click.prevent="orgStoreKeyAndRedirect(data)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a href="#" style="underline" @click.prevent="orgStoreKeyAndRedirect(data)">{{
                    data.item.OrganizationName
                    }}</a>
                    <p>
                      <b-form-checkbox v-if="data.item.IsCamp === 'true'" disabled checked="checked" value="checked">{{
                      i18n['data-table-search'].tcCamp
                      }}</b-form-checkbox>
                      <span v-if="!!data.item.OrgAddress1">{{ i18n['data-table-search'].tcAddress }}: {{
                      data.item.OrgAddress1
                      }}
                        {{ data.item.OrgAddress2 }} {{ data.item.OrgAddress3 }} {{ data.item.OrgState }}
                        {{ data.item.CountryAbr }}<br /></span>
                      <span v-if="!!data.item.OrgEmail">{{ i18n['data-table-search'].tcEmail }}:
                        <a :href="'mailto:' + data.item.OrgEmail">{{ data.item.OrgEmail }}</a><br /></span>
                      <span v-if="!!data.item.OrgPhoneNumberMasked">{{ i18n['data-table-search'].tcPhone }}: {{
                      data.item.OrgPhoneNumberMasked
                      }}<br /></span>
                      <span v-if="!!data.item.CampPresidentsName">{{ i18n['data-table-search'].tcCampPresident }}: {{
                      data.item.CampPresidentsName
                      }}<br />
                        <span v-if="!!data.item.CampPresidentsEmail" class="ml-3">{{
                        i18n['data-table-search'].tcCampPresidentsEmail
                        }}:
                          <a :href="'mailto:' + data.item.CampPresidentsEmail">{{ data.item.CampPresidentsEmail
                          }}</a><br /></span>
                        <span v-if="!!data.item.CampPresidentsPhone" class="ml-3">{{
                        i18n['data-table-search'].tcCampPresidentsPhone
                        }}:
                          {{ data.item.CampPresidentsPhone }}</span><br /></span>
                      <span v-if="!!data.item.AreaDirectorName">{{ i18n['data-table-search'].tcAreaDirector }}: {{
                      data.item.AreaDirectorName
                      }}<br />
                        <span v-if="!!data.item.AreaDirectorEmail" class="ml-3">{{
                        i18n['data-table-search'].tcAreaDirectorEmail
                        }}:
                          <a :href="'mailto:' + data.item.AreaDirectorEmail">{{ data.item.AreaDirectorEmail
                          }}</a><br /></span>
                        <span v-if="!!data.item.AreaDirectorPhone" class="ml-3">{{
                        i18n['data-table-search'].tcAreaDirectorPhone
                        }}: {{ data.item.AreaDirectorPhone
                          }}</span></span>
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Document'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a :href="data.item.Link" target="_blank">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a :href="data.item.Link" target="_blank">{{ data.item.Title }}</a>
                    <p>{{
                        i18n['data-table-search'].tcMRPCategory}}: {{ data.item.AssetParentCategory }}
                    </p>
                    <p>
                      {{ data.item.Description }}
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Video'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="" @click.prevent="mrpvideoKeyAndRedirect(data)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    {{ data.item.VideoParentCategory }} :
                    <a href="#" style="underline" @click.prevent="mrpvideoKeyAndRedirect(data)">{{
                    data.item.Title
                    }}</a>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'Store'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="" @click.prevent="setStoreKeyAndRedirect(data)" v-if="data.item.ThumbnailId">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a href="#" style="underline" @click.prevent="setStoreKeyAndRedirect(data)">
                     {{data.item.ItemName}}
                    </a><br/>
                    <p>{{
                        i18n['data-table-search'].tcStoreMerchCategory}}
                    </p>
                  </b-col>
                </b-row>
              </div>
              <div v-if="data.item.type === 'MediaResourcePage'">
                <b-row>
                  <b-col sm="2" offset="0" class="search-col" style="text-align: center">
                    <a href="#" style="underline" @click.prevent="setMRPKeyAndRedirect(data)">
                      <img :src="getImageLink(data.item.ThumbnailId)" class="thumbIcon mr-4" fluid
                        :alt="data.item.type" />
                    </a>
                  </b-col>
                  <b-col sm="10" offset="0" class="search-col">
                    <a href="#" style="underline" @click.prevent="setMRPKeyAndRedirect(data)">{{ data.item.Name }}</a>
                    <p>{{
                        i18n['data-table-search'].tcMRPCategory}}
                    </p>
                    <p>
                      {{ data.item.Description }}
                    </p>
                  </b-col>
                </b-row>
              </div>
            </template>

            <span slot="Media" slot-scope="data">
              <div class="icon-box" :class="{ checked: data.value }"></div>
            </span>

            <span slot="History" slot-scope="data">
              <a :href="data.value">{{ i18n['data-table-search'].tcView }}</a>
            </span>
          </b-table>
        </main>
        <footer v-if="internalToggle" class="d-flex align-items-center justify-content-between">
          <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
            <left-arrow></left-arrow>
          </div>
          <nav class="d-flex align-items-center justify-content-center">
            <div class="show d-flex align-items-center justify-content-end">
              <div>{{ i18n['data-table-search'].tcShow }}</div>
              <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
              <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
              <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
            </div>
            <div class="sep"></div>
            <div class="pagination justify-self">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" :hide-goto-end-buttons="true"
                @change="pageChanged"></b-pagination>
            </div>
          </nav>
          <div class="next" v-if="!!items && items.length > 0" @click="nextPage" :class="{
            hidden: Math.ceil(this.items.length / this.perPage) === this.currentPage,
          }">
            <right-arrow></right-arrow>
          </div>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import iCSV from '@/assets/svgs/iCSV.vue'
import iExcel from '@/assets/svgs/iExcel.vue'
import iFile from '@/assets/svgs/iFile.vue'
import leftArrow from '@/assets/svgs/arrow-left.vue'
import rightArrow from '@/assets/svgs/arrow-right.vue'
import ServiceDelete from '@/assets/svgs/service-card-delete.vue'
import ServiceEdit from '@/assets/svgs/service-card-edit.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
  name: 'data-table',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'data-table-search': {
            tcAddress: 'Address:',
            tcCamp: 'Camp',
            tcChurch: 'Church:',
            tcChurchPresenter: 'Church Presenter',
            tcDenomination: 'Church:',
            tcExportCSV: 'Export CSV',
            tcExportPDF: 'Export PDF',
            tcGideonCardFacility: 'GideonCard Facility',
            tcJoinDate: 'Join Date:',
            tcMember: 'Member',
            tcNoPresentationsMessage: 'No records were found.',
            tcPhone: 'Phone',
            tcPlacementFacility: 'Placement Facility',
            tcProspect: 'Prospect',
            tcSearch: 'Search',
            tcShow: 'Show',
            tcSpouse: 'Spouse',
            tcView: 'View',
            tcWidow: 'Widow',
          },
        }
      },
    },
    fields: Array,
    items: Array,
    listType: String,
    csvUrl: {
      type: String,
      default: '',
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    addItems: {
      type: Object,
      default: {
        text: 'Add Church',
        display: true,
        action: '#',
      },
    },
    hideHeader: { type: Boolean, default: false },
    includeAddButton: { type: Boolean, default: false },
    title: String,
    toggle: Boolean,
    toggleMe: { type: Boolean, default: true },
  },
  data() {
    return {
      // Church Table Settings
      sortBy: 'Church Name',
      sortDesc: false,
      perPage: 20,
      currentPage: 1,
      filter: null,
      number: 20,
      pag: '',
      internalToggle: true,
    }
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      loadMRPPrograms: 'mrp/loadMRPPrograms',
      setSearchTerm: 'search/setSearchTerm',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setMRPSearchVideoId: 'mrp/setMRPSearchVideoId',
      setMRPSelectedCategory: 'mrp/setMRPSelectedCategory',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedReportKey: 'reportParameter/setSelectedReportKey',
      setStoreSelectedSubCategoryId: 'gideonStore/setStoreSelectedSubCategoryId',
    }),
    handleToggleClick() {
      this.internalToggle = !this.internalToggle
    },
    pageChanged() {
      document.getElementById('results').scrollIntoView()
    },
    updateNumber(num) {
      this.perPage = num
    },
    prevPage() {
      if (this.currentPage == 1) {
        return
      }
      this.currentPage--
      document.getElementById('results').scrollIntoView()
    },
    nextPage() {
      if (!this.items) {
        return 1
      }
      if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
        return
      }
      this.currentPage++
      document.getElementById('results').scrollIntoView()
    },
    triggerAction(action) {
      this.$emit(action)
    },
    triggerActionWithValue(action, value) {
      this.$emit(action, value)
    },
    log(item) { },
    type(val) {
      return typeof val
    },
    notUndefined(val) {
      return !val ? '' : val
    },
    async orgStoreKeyAndRedirect(data) {
      if (data.item.IsChurch === 'true') {
        await this.setSelectedChurchKey(
          data.item.nte_entity_key ||
          data.item.church_key ||
          data.item.location_key ||
          data.item.chu_org_key ||
          data.item.id
        )
        this.$router.push({ path: '/programs/cm/church-profile' })
      } else if( data.item.IsGideonCardFacility === 'true') {
        await this.setSelectedFacilityKey(data.item.id)
        this.$router.push({ path: '/programs/gc/profile/summary/card_profile' })
      } else if (data.item.IsPlacementFacility === 'true') {
        await this.setSelectedLocationKey(data.item.id)
        this.$router.push({ path: '/programs/sd/location-profile' })
      }
    },
    async churchStoreKeyAndRedirect(data) {
      await this.setSelectedChurchKey(
        data.item.nte_entity_key ||
        data.item.church_key ||
        data.item.location_key ||
        data.item.chu_org_key ||
        data.item.MemberChurchKey
      )
      this.$router.push({ path: '/programs/cm/church-profile' })
    },
    async facilityStoreKeyAndRedirect(data) {
      await this.setSelectedFacilityKey(data.item.gcf_org_key)
      this.$router.push({ path: '/programs/gc/profile/summary/card_profile' })
    },
    async individualStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
    async viewKeyAndRedirect(data) {
      this.$router.push({ name: `${data}` })
    },
    async setMRPKeyAndRedirect(data) {
      var pgmId = null
      this.setLoadingStatus(true)
      if(!!data.item.ExternalUrl){
        let extUrl = data.item.ExternalUrl.replace('[ind_key]', this.userId)
        window.open(extUrl, '_blank')
      }
      else{
        await this.loadMRPPrograms({ lang: 'en', landingpage: 'MRP_Landing' }).then(() => {
          try {
            pgmId = this.MRPPrograms.find((mp) => mp.CategoryCode === data.item.VideoParentCategoryCode).ProgramId
          } catch (e) {
            next()
          }
        })
        await this.setMRPProgramId(pgmId)
        await this.setMRPSelectedCategory(data.item.VideoParentCategoryCode)
        this.$router.push({ name: 'mrp-program' })
      }
      this.setLoadingStatus(false)
    },
    async mrpvideoKeyAndRedirect(data) {
      var pgmId = null
      this.setLoadingStatus(true)
      await this.loadMRPPrograms({ lang: 'en', landingpage: 'MRP_Landing' }).then(() => {
        try {
          pgmId = this.MRPPrograms.find((mp) => mp.CategoryCode === data.item.VideoParentCategoryCode).ProgramId
        } catch (e) {
          next()
        }
      })
      await this.setMRPProgramId(pgmId)
      await this.setMRPSelectedCategory(data.item.VideoParentCategoryCode)
      await this.setMRPSearchVideoId(data.item.ItemID)
      await this.setMRPSelectedVideoId(data.item.ItemID)
      if (!!data.item.TopicId) {
        await this.setMRPSelectedTopicId(data.item.TopicId)
        await this.loadMRPProgramInfo({
          lang: this.prefCulture,
          restype: 'topics',
          programid: data.item.TopicParentId,
        })
        this.$router.push(`/resources/media-portal/program/topic-detail`)
      } else {
        this.$router.push(`/resources/media-portal/program/videos`)
      }
      this.setLoadingStatus(false)
    },
    async setStoreKeyAndRedirect(data) {
      if (data.item.ExternalUrl) {
        window.open(data.item.ExternalUrl, '_blank')
      } else {
        this.setStoreSelectedSubCategoryId(data.item.ItemID)
        this.$router.push({ name: 'store-category-products' })
      }
    },
    getImageLink(mediaid) {
      return this.mrpSiteCoreBaseURL + '/getimage/' + mediaid
    },
    async handleSearchTermChange() {
      await this.setSearchTerm({ genericTerm: this.filter })
    },
    async handlePDFClick(reportKey) {
      // go to the parameters screen
      let rpt = constantData.secure_report_array.find((raf) => raf.key === reportKey)
      if (!!rpt) {
        this.$router.push({ name: rpt.name })
      } else {
        await this.setSelectedReportKey(reportKey)
        this.$router.push({
          name: 'report-parameters',
        })
      }
    },
  },
  computed: {
    ...mapGetters({
      MRPPrograms: 'mrp/mrpPrograms',
      mrpSiteCoreBaseURL: 'mrp/mrpSiteCoreBaseURL',
      prefCulture: 'user/userPreferredCulture',
      searchTerms: 'search/searchTerms',
      userId: 'user/userId',
    }),
    rows() {
      return this.items ? this.items.length : 0
    },
  },
  created() {
    this.perPage = constantData.per_page_default
    this.filter = this.searchTerms.genericTerm
  },
  mounted() {
    this.internalToggle = this.toggleMe
  },
  components: {
    iCSV: iCSV,
    iExcel: iExcel,
    iFile: iFile,
    leftArrow: leftArrow,
    rightArrow: rightArrow,
    ServiceDelete: ServiceDelete,
    ServiceEdit: ServiceEdit,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.two_of_three {
  width: 600px !important;
}

.preheader {
  padding: 30px 32px;
  align-items: center;

  @include breakpoint(sm) {
    padding-left: 23px;
    padding-right: 23px;
  }

  h2 {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4;
    line-height: 44px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      padding-top: 0;
    }
  }
}
</style>
